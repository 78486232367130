<template>
  <b-form-group
    :disabled="disabled"
    :label-for="fieldTitle"
  >
    <div
      :class="{ 'show-privacy-border': displayHint }"
      class="schema-form-address-field-container"
    >
      <field-label
        :label="fieldTitle"
        :help-text="field.fieldHelpText"
        class="address-title"
      />
      <div
        class="address-fields-and-privacy-level-container"
        :class="privacyProtectedBorder"
      >
        <div
          v-if="displayHint"
          class="privacy-levels-container"
          :class="privacyProtectedBackground"
        >
          <div class="privacy-levels">
            <div class="privacy-level-container">
              <div class="privacy-level-radio">
                <b-row>
                  <b-form-radio
                    v-model="useHint"
                    :value="true"
                    :disabled="disabled || showRaSignup || noCompanyAddress"
                    class="float-left ml-3 mb-0"
                    @change="toggleHint($event)"
                  />
                </b-row>
              </div>
              <div class="privacy-level-icon">
                <privacy-protected-icon />
              </div>

              <div class="privacy-level-text">
                <template v-if="showRaSignup">
                  <b-button
                    variant="link"
                    class="float-left"
                    @click="signupForRA"
                  >
                    <p>
                      Use our address.
                    </p>
                    <p>
                      Keep yours private.
                    </p>
                  </b-button>
                </template>

                <template v-else-if="useCompanyMailingAddress">
                  <p>
                    Use my company mailing address.
                  </p>
                </template>

                <template v-else>
                  <p>
                    Use our address.
                  </p>
                  <p>
                    Keep yours private.
                  </p>
                </template>
              </div>
            </div>
            <div class="privacy-level-container">
              <div class="privacy-level-radio">
                <b-row>
                  <b-form-radio
                    v-model="useHint"
                    :value="false"
                    :disabled="disabled"
                    class="float-left ml-3 mb-0"
                    @change="toggleHint($event)"
                  />
                </b-row>
              </div>
              <template v-if="useCompanyMailingAddress">
                <div class="privacy-level-icon">
                  <privacy-protected-icon />
                </div>
                <div class="privacy-level-text">
                  <p>
                    Use a new shipping address.
                  </p>
                  <p>
                    This will not become publicly available.
                  </p>
                </div>
              </template>

              <template v-else>
                <div class="privacy-level-icon">
                  <privacy-unprotected-icon />
                </div>
                <div class="privacy-level-text">
                  <p>
                    Use my address.
                  </p>
                  <p>
                    This may become publicly available.
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="address-fields-container">
          <b-row>
            <b-col xs="12" :md="containsAddressField('line2') ? 8 : 12">
              <validation-wrapper :field="field" child-field="line1">
                <b-form-input
                  placeholder="Line 1"
                  :value="line1"
                  :disabled="useHint || disabled"
                  class="address-input-field"
                  @input="onInput({ line1: $event })"
                />
              </validation-wrapper>
            </b-col>

            <b-col xs="12">
              <validation-wrapper :field="field" child-field="line2">
                <b-form-input
                  v-if="containsAddressField('line2')"
                  placeholder="Line 2"
                  :value="line2"
                  :disabled="useHint || disabled"
                  class="address-input-field"
                  @input="onInput({ line2: $event })"
                />
              </validation-wrapper>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col xs="12" :md="containsAddressField('county') ? 4 : 6">
              <validation-wrapper :field="field" child-field="city">
                <b-form-input
                  placeholder="City"
                  :value="city"
                  :disabled="useHint || disabled"
                  class="address-input-field"
                  @input="onInput({ city: $event })"
                />
              </validation-wrapper>
            </b-col>

            <b-col v-if="containsAddressField('county')" xs="12" :md="4">
              <validation-wrapper :field="field" child-field="county">
                <b-form-input
                  placeholder="County"
                  :value="county"
                  :disabled="useHint || disabled"
                  class="address-input-field"
                  @input="onInput({ county: $event })"
                />
              </validation-wrapper>
            </b-col>

            <b-col xs="12" :md="containsAddressField('county') ? 4 : 6">
              <validation-wrapper :field="field" child-field="state_province_region">
                <b-form-input
                  placeholder="State/Province/Region"
                  :value="state_province_region"
                  :disabled="useHint || disabled"
                  class="address-input-field"
                  @input="onInput({ state_province_region: $event })"
                />
              </validation-wrapper>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col v-if="containsAddressField('country')" xs="12" md="8">
              <validation-wrapper :field="field" child-field="country">
                <b-form-select
                  placeholder="Country"
                  :value="country"
                  :options="countries()"
                  :disabled="useHint || disabled"
                  class="address-input-field"
                  @input="onInput({ country: $event })"
                />
              </validation-wrapper>
            </b-col>

            <b-col v-if="containsAddressField('zip_postal_code')" xs="12" md="4">
              <validation-wrapper
                :field="field"
                child-field="zip_postal_code"
                :rule="['US', 'USA', 'United States'].includes(country) ? 'zipcode' : ''"
              >
                <b-form-input
                  placeholder="Zip/Postal Code"
                  :value="zip_postal_code"
                  :disabled="useHint || disabled"
                  class="address-input-field"
                  @input="onInput({ zip_postal_code: $event })"
                />
              </validation-wrapper>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </b-form-group>
</template>

<script>
  import { throttle } from 'lodash/function'
  import { countries } from '@/common/modules/countries'
  import { mapGetters } from 'vuex'

  export default {
    name: 'SchemaFormAddressField',
    components: {
      FieldLabel:             () => import('@/components/SchemaForm/fields/SchemaFormFieldLabel'),
      ValidationWrapper:      () => import('@/components/SchemaForm/ValidationWrapper'),
      PrivacyProtectedIcon:   () => import('@images/illustrations/stageline/PrivacyProtectedIcon.svg'),
      PrivacyUnprotectedIcon: () => import('@images/illustrations/stageline/PrivacyUnprotectedIcon.svg'),
    },
    props: {
      value: Object,
      field: Object,
      validate: {
        type: Boolean,
        default: true,
      },
      skipHint: {
        type: Boolean,
        default: false,
      },
      isPersonAddress: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      contextualJurisdiction: [Object, String],
      initialUseHint: {
        type: Boolean,
        default: false,
      },
      showCompanyMailingAddressOption: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        useHint: false,
        fieldMetaType: null,
        nonHintValue: null,
        unitedStates: [undefined, null, false, '', 'USA', 'United States'],
      }
    },
    computed: {
      ...mapGetters('companies', [
          'hasProductOfTypeWithCompanyOrInCart',
          'domesticRegistration',
          'domesticRegistrationMailingAddress',
        ]
      ),
      ...mapGetters('schema', [
        'currentObject',
        'showAddressPrivacyBorder',
      ]),
      companyMailingAddress() {
        return Boolean(this.domesticRegistrationMailingAddress)
      },
      useHintAndCompanyMailingAddress() {
        return Boolean(this.useHint && this.showCompanyMailingAddressOption)
      },
      displayHint() {
        return !this.hideHint && this.showAddressPrivacyBorder
      },
      line1() {
        if (this.useHintAndCompanyMailingAddress) return this.domesticRegistrationMailingAddress?.line1 || null
        return this.value?.line1 || null
      },
      line2() {
        if (this.useHintAndCompanyMailingAddress) return this.domesticRegistrationMailingAddress?.line2 || null
        return this.value?.line2 || null
      },
      city() {
        if (this.useHintAndCompanyMailingAddress) return this.domesticRegistrationMailingAddress?.city || null
        return this.value?.city || null
      },
      county() {
        if (this.useHintAndCompanyMailingAddress) return this.domesticRegistrationMailingAddress?.county || null
        return this.value?.county || null
      },
      state_province_region() {
        if (this.useHintAndCompanyMailingAddress) return this.domesticRegistrationMailingAddress?.state_province_region
        return this.value?.state_province_region || null
      },
      country() {
        if (this.useHintAndCompanyMailingAddress) return this.mapCountryCode(this.domesticRegistrationMailingAddress?.country)
        return this.unitedStates.includes(this.value?.country) ?
          'US' :
          this.value?.country
      },
      zip_postal_code() {
        if (this.useHintAndCompanyMailingAddress) return this.domesticRegistrationMailingAddress?.zip_postal_code || null
        return this.value?.zip_postal_code || null
      },
      substitute_ra_address() {
        // TODO: Add this flag to addresses eventually so it can be dynamic...
        return this.value?.substitute_ra_address || null
      },
      fieldTitle() {
        return this.field.original_field_label || this.field.meta.title || this.field.title
      },
      showRaSignup() {
        if (this.showCompanyMailingAddressOption) return false
        return this.hasContextualJurisdiction &&
          this.hasRAServiceAsContextWithNoSkip &&
          !this.hasProductOfTypeWithCompanyOrInCart('registered-agent', this.raJurisdictionForForm)
      },
      useCompanyMailingAddress() {
        return this.showCompanyMailingAddressOption
      },
      noCompanyAddress() {
        return this.showCompanyMailingAddressOption && !this.companyMailingAddress
      },
      hasRAServiceAsContextWithNoSkip() {
        return this.field.meta.suggestion?.context &&
          this.field.meta.suggestion?.context.name === 'registered-agent-service' &&
          !this.field.meta.suggestion?.context.skip_hint
      },
      hasContextualJurisdiction() {
        return this.contextualJurisdiction !== undefined
      },
      privacyProtectedBorder() {
        return this.useHint ? 'protected-border' : 'unprotected-border'
      },
      privacyProtectedBackground() {
        return this.useHint ? 'protected-background' : 'unprotected-background'
      },
      hideHint() {
        return !this.field.meta?.suggestion ||
          this.field.meta?.suggestion?.context?.skip_hint ||
          this.field?.parent?.meta?.suggestion?.context?.skip_hint ||
          this.currentObject?.type === 'registered-agent' ||
          (this.contextualJurisdiction?.state_province_region === 'Federal' && !this.raJurisdictionForForm)
      },
      toggleHintOnMounted() {
        // Using double equals to account for null and undefined
        return this.useHint && (this.value == null || this.value?.line1 == null)
      },
      raJurisdictionForForm() {
        return this.contextualJurisdiction.state_province_region === 'Federal' ? this.domesticRegistration?.jurisdiction : this.contextualJurisdiction
      },
    },
    watch: {
      showRaSignup: function () {
        if (this.showRaSignup && this.useHint) {
          this.manuallyTriggerToggleHint(false)
        }
      },
      initialUseHint: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.useHint = this.initialUseHint
        }
      },
    },
    beforeMount() {
      // If the field doesn't have a children key, but has a fields key, convert fields to children
      // (i.e. for use in Stageline)
      if (this.field.fields && !this.field.children) this.field['children'] = this.field.fields

      // sync up non-reactive SchemaFormField stuff manually when validation happens
      this.field.onValidation = throttle(() => this.$forceUpdate(), 250, { trailing: false })
    },
    mounted() {
      this.useHint = this.noCompanyAddress ? false : this.initialUseHint
      if (this.toggleHintOnMounted && !this.isPersonAddress) this.$emit('suggestion-toggled', this.useHint)
      else this.handleRadioState()
    },
    methods: {
      handleRadioState(){
        const useOurAddressDisabled = this.disabled
          || this.showRaSignup
          || this.noCompanyAddress
          || this.skipHint

        if(!useOurAddressDisabled && !this.useHint && typeof this.showRaSignup !== 'undefined'){
          this.manuallyTriggerToggleHint(true)
        }
      },
      onInput(updates) {
        if (this.unitedStates.includes(updates?.country)) updates.country = 'US'
        if (!this.useHint && this.nonHintValue) {
          Object.assign(this.nonHintValue, updates)
        }
        const newAddress = Object.assign(this.value, updates)
        this.$emit('input', newAddress)
      },
      countries: () => countries,
      containsAddressField(field) {
        return Object.keys(this.field).includes('children')
          && this.field.children.some(child => child.name === field)
      },
      isObject(obj) {
        return !!obj && typeof(obj) === 'object'
      },
      manuallyTriggerToggleHint(hintValue) {
        this.useHint = hintValue
        this.$emit('suggestion-toggled', hintValue)
      },
      signupForRA() {
        this.$emit('ra-signup')
        this.manuallyTriggerToggleHint(true)
      },
      toggleHint(event) {
        if (!event) {
          this.$emit('input', this.nonHintValue)
        }
        this.$emit('suggestion-toggled', event)
      },
      mapCountryCode(country) {
        for (const countryMap of this.countries()) {
          if (countryMap.text.includes(country)) return countryMap.value
        }
        return this.unitedStates.includes(country) ? 'US' : null
      },
    },
  }
</script>

<style scoped lang="scss">
$color-protected: $ct-ui-primary-light;
$color-unprotected: #FFF2CC;

.schema-form-address-field-container {
  display: flex;
  flex-flow: column nowrap;
  margin: 2.75em 0;

  .address-title {
    font-size: 1em;
    font-weight: 500;
    margin: 0 !important;
    padding-bottom: 0.4em;
  }

  .address-fields-and-privacy-level-container {
    display: flex;
    flex-flow: column nowrap;
    border-width: 0.375em;
    border-style: solid;

    .privacy-levels-container {
      display: flex;
      flex-flow: column nowrap;
      padding: 0.75em 1.375em 1.0625em 1.375em;

      .privacy-levels {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;

        .privacy-level-container {
          width: 50%;
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: flex-start;

          .privacy-level-radio {
            padding-top: 0.25em;
            padding-right: 0.375em;
          }

          .privacy-level-icon {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            padding-top: 0.25em;
            padding-left: 1.375em;
          }

          .privacy-level-text {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: center;
            padding-left: 0.85em;

            p {
              margin: 0;
              font-size: 0.875em;
            }
          }
        }
      }
    }

    .address-fields-container {
      padding: 2.0em 3.25em;

      .address-input-field {
        font-size: 0.875em;
        font-weight: 600;
      }
    }

    .protected-background {
      background: $color-protected;
    }

    .unprotected-background {
      background: $color-unprotected;
    }
  }

  .protected-border {
    border-color: $color-protected;
  }

  .unprotected-border {
    border-color: $color-unprotected;
  }
}

.schema-form-address-field-container:not(.show-privacy-border) {
  .address-title {
    font-size: 1em;
    font-weight: 600;
  }

  .address-fields-and-privacy-level-container {
    border: 0 solid white;

    .address-fields-container {
      padding: 0;

      .address-input-field {
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 400;
      }
    }
  }
}


@media only screen and (max-width: 600px) {
  .schema-form-address-field-container {

    .address-fields-and-privacy-level-container {

      .privacy-levels-container {

        .privacy-levels {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          row-gap: 1.25em;

          .privacy-level-container {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
