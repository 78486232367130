var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "schema-element",
      class: _vm.elementClasses,
      attrs: { "data-field-path": _vm.field.path },
    },
    [
      _vm.field.meta.suggestion &&
      !["address", "registered_agent"].includes(_vm.field.meta.type)
        ? _c(
            "div",
            [
              _vm.showRaSignup
                ? _c(
                    "b-row",
                    { staticClass: "mb-0" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-left",
                          attrs: { variant: "link" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("ra-signup")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        Sign up to use our address to keep yours private\n      "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _vm.showHint
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "float-left ml-3 mb-3",
                          attrs: { disabled: _vm.isDisabled },
                          on: { change: _vm.onUseHintToggle },
                          model: {
                            value: _vm.useHint,
                            callback: function ($$v) {
                              _vm.useHint = $$v
                            },
                            expression: "useHint",
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.field.meta.suggestion.hint) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.field.type === "disclaimer"
        ? _c(
            "div",
            {
              staticClass: "alert alert-info text-center",
              attrs: {
                id: "duplicate-add-button-breaker-do-not-filter-just-hide-if-needed",
                role: "alert",
              },
            },
            [_c("b", [_vm._v(_vm._s(_vm.field.description) + " ")])]
          )
        : _vm._e(),
      _vm.field.type === "markup"
        ? _c("div", [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.sanitizedFieldTitle) },
            }),
          ])
        : _vm._e(),
      _vm.field.type === "string"
        ? _c(
            "div",
            [
              _c(
                "b-form-group",
                { attrs: { disabled: _vm.isUsingHint } },
                [
                  _c("schema-form-field-label", {
                    attrs: {
                      label: _vm.fieldTitle,
                      "help-text": _vm.field.fieldHelpText,
                    },
                  }),
                  _c(
                    "validation-wrapper",
                    {
                      attrs: { field: _vm.field, rule: _vm.getRuleForField() },
                    },
                    [
                      _vm.field.meta.type === "select"
                        ? _c("b-form-select", {
                            staticClass: "text-left",
                            attrs: { options: _vm.field.meta.options },
                            on: { input: _vm.valueUpdated },
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          })
                        : _vm.field.meta.type === "number"
                        ? _c("b-form-input", {
                            attrs: {
                              type: "number",
                              min: _vm.field.meta.min_value,
                              max: _vm.field.meta.max_value,
                            },
                            on: { input: _vm.valueUpdated },
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          })
                        : _vm.field.meta.type === "phone"
                        ? _c("schema-form-phone-field", {
                            attrs: { value: _vm.value, field: _vm.field },
                            on: { input: _vm.valueUpdated },
                          })
                        : _vm.field.meta.type === "date"
                        ? _c("schema-form-date-field", {
                            attrs: { value: _vm.value, field: _vm.field },
                            on: { input: _vm.valueUpdated },
                          })
                        : _vm.field.meta.type === "ssn"
                        ? _c("schema-form-ssn-field", {
                            attrs: { value: _vm.value, field: _vm.field },
                            on: { input: _vm.valueUpdated },
                          })
                        : _c("b-form-input", {
                            attrs: {
                              disabled:
                                _vm.disabled || _vm.disablePrefilledFields,
                              "max-length": _vm.field.meta.character_limit,
                            },
                            on: { input: _vm.valueUpdated },
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.field.type === "boolean"
        ? _c(
            "div",
            [
              _c(
                "validation-wrapper",
                { attrs: { field: _vm.field } },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { disabled: _vm.disabled },
                      on: { input: _vm.valueUpdated },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    },
                    [
                      _c("schema-form-field-label", {
                        attrs: {
                          label: _vm.fieldTitle,
                          "help-text": _vm.field.fieldHelpText,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.field.type === "radio"
        ? _c(
            "div",
            [
              _c(
                "b-form-group",
                [
                  _c("schema-form-field-label", {
                    attrs: {
                      label: _vm.fieldTitle,
                      "help-text": _vm.field.fieldHelpText,
                    },
                  }),
                  _c(
                    "validation-wrapper",
                    { attrs: { field: _vm.field } },
                    [
                      _c("b-form-radio-group", {
                        staticClass: "float-left",
                        attrs: {
                          id: "field.name",
                          name: "value",
                          disabled: _vm.disabled,
                          options: _vm.field.meta.options,
                        },
                        on: { input: _vm.valueUpdated },
                        model: {
                          value: _vm.value,
                          callback: function ($$v) {
                            _vm.value = $$v
                          },
                          expression: "value",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.field.type === "phone"
        ? _c(
            "div",
            [
              _c(
                "b-form-group",
                { attrs: { disabled: _vm.isUsingHint } },
                [
                  _c("schema-form-field-label", {
                    attrs: {
                      label: _vm.fieldTitle,
                      "help-text": _vm.field.fieldHelpText,
                    },
                  }),
                  _c("schema-form-phone-field", {
                    attrs: { field: _vm.field },
                    on: { input: _vm.valueUpdated },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.field.meta.type === "person" ||
      _vm.field.meta.type === "registered_agent"
        ? _c("schema-form-person-field", {
            attrs: {
              field: _vm.field,
              "contextual-jurisdiction": _vm.contextualJurisdiction,
              disabled: _vm.disabled || _vm.disablePrefilledFields,
              "ra-address": _vm.raAddress,
            },
            on: {
              "ra-signup": function ($event) {
                return _vm.$emit("ra-signup")
              },
              input: _vm.valueUpdated,
              "show-contact-modal": _vm.showContactModal,
              "suggestion-toggled": function ($event) {
                return _vm.suggestionToggled($event)
              },
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
      _vm.field.meta.type === "address"
        ? _c("schema-form-address-field", {
            attrs: {
              field: _vm.field,
              "skip-hint": _vm.skipHint,
              "is-person-address": _vm.isPersonAddress,
              disabled: _vm.disabled,
              "contextual-jurisdiction": _vm.contextualJurisdiction,
              "initial-use-hint":
                _vm.disablePrefilledFields || _vm.addressMatch,
              "show-company-mailing-address-option":
                _vm.showCompanyMailingAddressOption,
            },
            on: {
              input: _vm.valueUpdated,
              "ra-signup": function ($event) {
                return _vm.$emit("ra-signup")
              },
              "suggestion-toggled": function ($event) {
                return _vm.suggestionToggled($event)
              },
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
      _vm.shouldRenderChildren && _vm.field.children.length
        ? _c(
            "div",
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.fieldTitle, disabled: _vm.isUsingHint } },
                [
                  _c(
                    "validation-wrapper",
                    { attrs: { field: _vm.field } },
                    [
                      _c("schema-form-field-list", {
                        attrs: {
                          fields: _vm.field.children,
                          disabled: _vm.disabled,
                          "contextual-jurisdiction": _vm.contextualJurisdiction,
                        },
                        on: { input: _vm.childValuesUpdated },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.field.canRemove && !_vm.disabled
        ? _c(
            "b-button",
            {
              staticClass: "mr-1",
              attrs: { variant: "outline-danger", size: "sm" },
              on: {
                click: function ($event) {
                  return _vm.field.remove()
                },
              },
            },
            [_vm._v("\n    - Remove\n  ")]
          )
        : _vm._e(),
      _vm.field.canHaveMultiple && !_vm.disabled
        ? _c(
            "b-button",
            {
              attrs: { size: "sm", variant: "outline-primary" },
              on: {
                click: function ($event) {
                  return _vm.field.duplicate()
                },
              },
            },
            [
              _vm._v(
                "\n    + Add another " +
                  _vm._s(
                    _vm.field.meta
                      ? _vm.field.meta.title || _vm.field.title
                      : _vm.field.title
                  ) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm.field.type === "object" ? _c("hr") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }