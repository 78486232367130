<template>
  <div>
    <b-form-group class="schema-form-person-field">
      <schema-form-field-label :label="fieldTitle" :help-text="field.fieldHelpText" />
      <b-form-select
        v-if="!disabled"
        v-model="person"
        :disabled="disabled"
        :options="peopleOptions"
        @input="personChosen"
      />
    </b-form-group>

    <b-row class="mt-2">
      <b-col xs="12" md="6">
        <schema-form-field
          v-if="firstNameField"
          :field="firstNameField"
          :disabled="disabled"
          :contextual-jurisdiction="contextualJurisdiction"
          @suggestion-toggled="$emit('suggestion-toggled', $event)"
          @input="onInputOtherField"
        />
      </b-col>

      <b-col xs="12" md="6">
        <schema-form-field
          v-if="lastNameField"
          :field="lastNameField"
          :disabled="disabled"
          :contextual-jurisdiction="contextualJurisdiction"
          @suggestion-toggled="$emit('suggestion-toggled', $event)"
          @input="onInputOtherField"
        />
      </b-col>
    </b-row>

    <schema-form-field
      v-for="subField in otherFields"
      :key="subField.id"
      :field="subField"
      :disabled="disabled"
      :contextual-jurisdiction="contextualJurisdiction"
      @suggestion-toggled="$emit('suggestion-toggled', $event)"
      @input="onInputOtherField"
    />

    <schema-form-field
      v-if="addressField"
      ref="person-address-field"
      :field="addressField"
      :disabled="disabled"
      :contextual-jurisdiction="contextualJurisdiction"
      :ra-address="raAddress"
      :is-person-address="true"
      @ra-signup="$emit('ra-signup')"
      @suggestion-toggled="$emit('suggestion-toggled', $event)"
      @input="onInput"
    />
  </div>
</template>

<script>
  import { throttle } from 'lodash/function'
  import { mapActions, mapGetters } from 'vuex'
  import _ from 'lodash'

  export default {
    name: 'SchemaFormPersonField',

    components: {
      SchemaFormField:       () => import('./SchemaFormField'),
      SchemaFormFieldLabel:  () => import('./SchemaFormFieldLabel'),
    },

    props: {
      value: Object,
      field: Object,
      contextualJurisdiction: [Object, String],
      disabled: {
        type: Boolean,
        default: true,
      },
      raAddress: Object,
    },

    data() {
      return {
        person: this.personDefault(),
      }
    },

    computed: {
      ...mapGetters('schema', [
        'currentProductKind',
        'useWrapperValidations',
      ]),
      ...mapGetters('stagelineSchemaForm', [
        'verifyOrderPeople',
      ]),
      ...mapGetters('account', ['peopleWithAllRequiredInfo', 'emailFor', 'phoneFor']),
      ...mapGetters('companies', ['hasProductOfTypeWithCompanyOrInCart']),
      ...mapGetters('jurisdictions', ['convertToBaseJurisdiction']),

      fieldTitle() {
        return this.field.meta.title || this.field.title
      },

      addressField() {
        if (!Array.isArray(this.field.children)) {
          return null
        }

        return this.field.children.find(f => f.meta.type === 'address')
      },

      firstNameField() {
        return this.field.children.find(f => f.name === 'first_name')
      },

      lastNameField() {
        return this.field.children.find(f => f.name === 'last_name')
      },

      otherFields() {
        return this.field.children.filter(
          f => !['first_name', 'last_name', 'address'].includes(f.name)
        )
      },

      updatedPeople() {
        return !_.isEmpty(this.verifyOrderPeople) ? this.verifyOrderPeople : this.peopleWithAllRequiredInfo
      },

      isWyomingSRA() {
        if (this.contextualJurisdiction?.state_province_region === 'Wyoming' &&
          this.currentProductKind === 'registered_agent_product') {
          return true
        }
        return false
      },

      peopleOptions() {
        const options = this.updatedPeople.map(p => ({
          text: this.displayName(p),
          value: {
            first_name: p.first_name,
            last_name: p.last_name,
            id: p.id,
            official_is_company: p.contact_is_company || false,
            company_name: p.contact_is_company ? p.contact_company_name : '',
            substitute_ra_address: p.substitute_ra_address,
            address: p.person_addresses.find(pa => pa.kind === 'primary') || p.person_addresses[0],
            phone_number: this.phoneFor(p),
            email_address: this.emailFor(p),
          },
        }))

        options.unshift({ text: `Add a new Contact`, value: '' })

        const noOfficialIsCompany = options.filter(person => !person.value.official_is_company)

        return this.isWyomingSRA || this.field.name === 'responsible_party' ? noOfficialIsCompany : options
      },
    },

    watch: {
      verifyOrderPeople: {
        deep: true,
        handler() {
          // this ensures that the dropdown stays populated after adding a new contact
          this.setPerson()
        },
      },
      peopleWithAllRequiredInfo: {
        deep: true,
        handler() {
          // this ensures that the dropdown stays populated after adding a new contact
          this.setPerson()
        },
      },
    },

    mounted() {
      this.setVerifyOrderPeople(this.updatedPeople)

      // sync up non-reactive SchemaFormField stuff manually when validation happens.
      this.field.onValidation = throttle(() => this.$forceUpdate(), 250, { trailing: false })
      this.setPerson()
    },

    methods: {
      ...mapActions('companies', ['loadActiveRAServices']),
      ...mapActions('stagelineSchemaForm', [
        'setVerifyOrderPeople',
      ]),

      setPerson() {
        if (this.value && this.value?.first_name) {
          // This handles stageline initialization.
          this.person = this.peopleOptions
            .find(p => {
              const pFirstName = p.value.first_name && p.value.first_name.trim()
              const pLastName = p.value.last_name && p.value.last_name.trim()
              const firstName = this.value.first_name && this.value.first_name.trim()
              const lastName = this.value.last_name && this.value.last_name.trim()
              return pFirstName && pFirstName === firstName && pLastName && pLastName === lastName
            })?.value || this.personDefault()
        } else if (this.field && this.field?.value?.first_name) {
          // This handles hire us initialization.
          this.person = this.peopleOptions
            .find(p => {
              const pFirstName = p.value.first_name && p.value.first_name.trim()
              const pLastName = p.value.last_name && p.value.last_name.trim()
              const firstName = this.field.value.first_name && this.field.value.first_name.trim()
              const lastName = this.field.value.last_name && this.field.value.last_name.trim()
              return pFirstName && pFirstName === firstName && pLastName && pLastName === lastName
            })?.value
        }
      },

      personChosen() {
        // If add a contact is chosen
        if (typeof(this.person) !== 'object' && this.person === '') {
          this.person = this.personDefault()

          this.$emit('show-contact-modal')
        } else {
          let substituted = false
          if (this.$refs['person-address-field']) {
            if (this.person?.substitute_ra_address && this.$refs['person-address-field'].field?.meta?.suggestion) {
              substituted = this.attemptAddressSubstitution()
            } else {
              this.$refs['person-address-field'].useHint = false
            }
          }

          if(!substituted) {
            this.onInput(false)
          }
        }
      },
      attemptAddressSubstitution(){
        if (this.contextualJurisdiction) {
          const jurisdiction = this.convertToBaseJurisdiction(this.contextualJurisdiction)

          if (this.hasProductOfTypeWithCompanyOrInCart('registered-agent', jurisdiction)) {
            if (this.$refs['person-address-field']) {
              this.$refs['person-address-field'].useHint = true
              this.$refs['person-address-field'].onUseHintToggle()
              return true
            }
          }
        }
        return false
      },

      onInput(triggeredFromHint) {
        if (triggeredFromHint) {
          if (this.person) this.person.address = triggeredFromHint.value
        } else {
          if (this.person?.address && this.person?.address?.value) {
            this.person.address = this.person.address.value
          }
        }

        // Hack the local updated address into the temp OVR people addresses
        const people = this.updatedPeople
        const personIndex = this.updatedPeople.findIndex(b => b.id === this.person?.id)
        if (personIndex >= 0) {
          people[personIndex].person_addresses[0] = this.person?.address
          this.setVerifyOrderPeople(people)
        }

        // In cases like Filer, the prefilled data is our data and will never be in the user’s account
        // “People” so it will always not find the person and then push up an undefined value or personDefault.
        // Filer combined with an RA product will always have it’s fields here disabled because disableFilerFields
        // in SchemaFormField so it will always push up broken data and fail the validation check even
        // though data is in the field.  Disabled fields should never push up a potentially blank
        // person so just always push up what the value actually is not what can be found in “People”.
        const valueToEmit = this.disabled ? this.value : this.person
        this.$emit('input', valueToEmit)
      },

      onInputOtherField(ev) {
        if (this.person && ev) {
          this.$set(this.person, ev.name, ev.value)

          this.onInput()
        }
      },

      personDefault() {
        return {
          first_name: null,
          last_name: null,
          substitute_ra_address: false,
          address: { country: "US" },
          email_address: null,
        }
      },

      displayName(person) {
        if (person.contact_is_company) {
          return person.contact_company_name
        }
        return person.first_name + ' ' + person.last_name
      },
    },
  }
</script>

<style scoped lang="scss">
  @media only screen and (max-width: 660px) {
    .schema-form-person-field {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      row-gap: 1.0em;
    }
  }
</style>
